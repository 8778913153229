import { Grid, Typography, withStyles } from '@material-ui/core';
import { Component } from 'react';

const styles = theme => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
});

class PageHeader extends Component {
  render() {
    return (
      <Grid container justifyContent="center" className={this.props.classes.container}>
        <Grid item xs={12} sm={9}>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            {this.props.title}
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" component="p">
            {this.props.subtitle}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PageHeader);
