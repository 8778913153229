import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Link,
  Typography,
  withStyles
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ExpandMore, Launch } from '@material-ui/icons';
import { Component } from 'react';
import PageHeader from '../common/PageHeader';

const externalIcon = <Launch style={{ fontSize: '1rem', position: 'relative', top: 2 }}/>;

const accordion = [
  {
    topic: 'Installation',
    content: <span>
      <p>In order to install Conventionsbot in your Github account you need to be an organization owner. If you don't have sufficient privileges please contact your Github organization administrator. You can also install Conventionsbot in your private Github account. Installation process for organization and individual Github users is the same.</p>
      <p><strong>Step 1</strong> - Log in to Conventionsbot administration panel</p>
      <p>Go to the login page (use the top right corner button). Log in to the admin panel using your Github account. During the first log in Github will ask you to authorize Naming Conventions Bot app. This is a one off procedure and further logins won't require authorization.</p>
      <p><strong>Step 2</strong> - Install Naming Conventions Bot Github app</p>
      <p>Open user menu (top right corner) and select Install Integration. You'll be redirected to Github app page. Select plan and account Conventionsbot installation, submit your choice. In the next screen review and confirm your order.</p>
      <p>In the next screen select repositories to be tracked by Conventionsbot. You can track all repositories (this applies to all future repositories) or select individual repositories.</p>
      <p>Important: selecting repositories to track doesn't automatically enable naming conventions validation. It only makes repositories "visible" to the Conventionsbot app. You can learn more in the next chapter.</p>
      <p>For more detailed procedure please read this <Link href="https://docs.github.com/en/github/customizing-your-github-workflow/purchasing-and-installing-apps-in-github-marketplace" target="_blank">Github article {externalIcon}</Link></p>
      <p><strong>Video</strong></p>
      <p><div style={{ position: 'relative', paddingBottom: '59%', height: 0 }}>
        <iframe style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }} src="https://www.youtube.com/embed/ACafCQ-LhS4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
      </div></p>
    </span>
  },
  {
    topic: 'Configuration',
    content: <span>
      <p>After successful installation you can start managing naming conventions. Go to installation management page using selector located in the top right corner or use "Manage" button in the main screen (installation list).</p>
      <p><strong>Global settings</strong></p>
      <p>Conventionsbot allows to define naming conventions that are global to the entire organization. By default all repositories inherit global naming conventions. If global naming convention of specific target (branch name, pull request name, commit message) is not configured Conventionsbot won't be validating it. Use "Edit" link of relevant target to open a dialog box.</p>
      <p><strong>Modifying naming conventions</strong></p>
      <p>Once dialog box is opened you can provide a regular expression that will be used for naming convention validation. You can define individual naming conventions for all three targets (branch name, pull request name, commit message). If you want to remove convention please use "Remove" button. Once your regular expression is ready use "Save" button to persist the change.</p>
      <p><strong>Repository settings</strong></p>
      <p>Conventionsbot allows to override global naming conventions for the individual repositories. You can modify them in the same way as Global Settings conventions.</p>
      <p><strong>Enabling repositories</strong></p>
      <p>In order to enable validation for specific repositories please use toggle in the top right corner of repository card. Repositories validation is disabled by default and has to be enabled for each repository individually. Alternatively you can enable validation for all repositories using "Enable for all" toggle in the "Global Settings" card. You can enable validation for all repositories only if your bought a plan that supports more repositories than you have.</p>
      <p><strong>Using templates</strong></p>
      <p>In order to speed up naming conventions set up process there are several ready to use templates available. You can select a template from the list in the dialog box. At the moment there's no option to manage templates. They are the same for all Conventionsbot users.</p>
      <p><strong>Video</strong></p>
      <p><div style={{ position: 'relative', paddingBottom: '57%', height: 0 }}>
        <iframe style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }} src="https://www.youtube.com/embed/ZYfIQR39hEs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
      </div></p>
    </span>
  },
];

const styles = {
  heading: {
    color: grey[800],
    fontWeight: 700,
  }
};

class Docs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
    };
  }

  handleChange = (index) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? index : null });
  };

  render() {
    return (
      <Container>
        <PageHeader
          title="Docs"
          subtitle="If you couldn't find an answer for your question please also take a look at the Support section or contact us."
        />
        <Grid container>
          <Grid item xs={12}>
            {accordion.map((item, index) =>
              <Accordion key={index} expanded={this.state.expanded === index} onChange={this.handleChange(index)}>
                <AccordionSummary
                  expandIcon={<ExpandMore/>}
                  aria-controls={'panel' + index + '-content'}
                  id={'panel' + index + '-header'}
                >
                  <Typography className={this.props.classes.heading}>{item.topic}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {item.content}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(Docs);
