import { Card, CardContent, CardHeader, CardMedia, Container, Grid, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Component } from 'react';
import PageHeader from '../common/PageHeader';
import bitbucket from './img/bitbucket.png';
import checks from './img/checks.gif';
import gitlab from './img/gitlab.png';
import global from './img/global.png';
import panel from './img/panel.png';
import templates from './img/templates.gif';

const features = [
  {
    title: 'Admin panel',
    image: panel,
    description: 'Conventionsbot provides a convenient admin panel for naming conventions management. There is no need of registration or account creation. You can log in using your Github account.'
  },
  {
    title: 'Global and local config',
    image: global,
    description: 'You can define default naming conventions for all of the repositories in your Github account, and override them for individual repositories if necessary.'
  },
  {
    title: 'Convention templates',
    image: templates,
    description: 'You can use one of the available templates to speed up naming conventions configuration, or configure conventions using your own regular expression.'
  },
  {
    title: 'Individual checks',
    image: checks,
    description: 'Conventionsbot creates three individual checks in every pull request, so you have more flexibility on branch protection rules set up.'
  },
];

const styles = theme => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    marginBottom: 'auto',
  },
  cardHeader: {
    color: grey[800],
    backgroundColor: grey[200],
  },
  cardMedia: {
    height: 0,
    paddingTop: '58.33%', // 350/600
  },
  logo: {
    width: '100%'
  }
});

class Product extends Component {
  render() {
    return (
      <Container>
        <PageHeader
          title="Product"
          subtitle="Learn more about Conventionsbot features"
        />
        <Grid container spacing={3} className={this.props.classes.container}>
          {features.map((feature, index) => <Grid item xs={12} sm={6} md={4} key={index}>
              <Card elevation={2} className={this.props.classes.card}>
                <CardHeader
                  title={feature.title}
                  titleTypographyProps={{ align: 'center' }}
                  className={this.props.classes.cardHeader}
                />
                <CardMedia image={feature.image} title={feature.title} className={this.props.classes.cardMedia}/>
                <CardContent className={this.props.classes.cardContent}>
                  <Typography variant="body1">{feature.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        <Grid container className={this.props.classes.container}>
          <Grid item sm={12}>
            <Typography variant="h5" align="center" color="textSecondary" component="p">
              Coming soon...
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={this.props.classes.container}>
          <Grid item xs={12} sm={6} md={4}>
            <Card elevation={2}>
              <CardHeader
                title="Bitbucket integration"
                titleTypographyProps={{ align: 'center' }}
                className={this.props.classes.cardHeader}
              />
              <CardContent>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <img src={bitbucket} alt="Bitbucket integration" className={this.props.classes.logo}/>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card elevation={2}>
              <CardHeader
                title="Gitlab integration"
                titleTypographyProps={{ align: 'center' }}
                className={this.props.classes.cardHeader}
              />
              <CardContent>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <img src={gitlab} alt="Gitlab integration" className={this.props.classes.logo}/>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Product);
