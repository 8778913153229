import { withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Component } from 'react';
import { Link } from 'react-router-dom';

const styles = theme => ({
  link: {
    color: grey[100],
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textDecoration: 'none',
    textTransform: 'uppercase',
  }
});

class MenuLink extends Component {
  render() {
    return (
      <div>
        <Link to={this.props.to} className={this.props.classes.link}>{this.props.name}</Link>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MenuLink);
