import { Button, Link, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Person } from '@material-ui/icons';
import { Component } from 'react';
import config from '../../config';

const styles = theme => ({
  login: {
    color: grey[100],
    marginLeft: theme.spacing(4),
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'none',
    }
  }
});

class LoginButton extends Component {
  render() {
    return (
      <Link href={config.PANEL_URL} className={this.props.classes.login}>
        <Button variant="outlined" color="inherit" startIcon={<Person/>}>Log in</Button>
      </Link>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LoginButton);
