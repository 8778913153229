import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  withStyles
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
  ChromeReaderModeOutlined,
  DescriptionOutlined,
  HelpOutline,
  MailOutlined,
  Menu as MenuIcon,
  MenuBookOutlined,
  MonetizationOnOutlined,
  Person,
  SecurityOutlined
} from '@material-ui/icons';
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import config from '../../config';
import Brand from './Brand';
import LoginButton from './LoginButton';
import MenuLink from './MenuLink';

const commonLinks = [
  {
    to: '/product',
    name: 'Product',
    icon: <ChromeReaderModeOutlined/>
  },
  {
    to: '/pricing',
    name: 'Pricing',
    icon: <MonetizationOnOutlined/>
  },
  {
    to: '/support',
    name: 'Support',
    icon: <HelpOutline/>
  },
  {
    to: '/docs',
    name: 'Docs',
    icon: <DescriptionOutlined/>
  },
];

const extraLinks = [
  {
    to: '/terms',
    name: 'Terms of service',
    icon: <MenuBookOutlined/>
  },
  {
    to: '/privacy',
    name: 'Privacy',
    icon: <SecurityOutlined/>
  },
  {
    to: '/contact',
    name: 'Contact',
    icon: <MailOutlined/>
  },
];

const styles = theme => ({
  list: {
    width: 220,
  },
  menuIcon: {
    color: grey[100],
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuLink: {
    color: grey[900],
    textDecoration: 'none',
  },
  menuLinks: {
    alignItems: 'center',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
});

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.handleToggleDrawer = this.handleToggleDrawer.bind(this);
  }

  handleToggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ open: open });
  };

  render() {
    return (
      <AppBar position="static" className={this.props.classes.menu}>
        <Toolbar>
          <Brand/>
          <div className={this.props.classes.menuLinks}>
            {commonLinks.map(link => <MenuLink key={link.to} to={link.to} name={link.name}/>)}
            <LoginButton/>
          </div>
          <div className={this.props.classes.menuButton}>
            <IconButton onClick={this.handleToggleDrawer(true)}>
              <MenuIcon className={this.props.classes.menuIcon}/>
            </IconButton>
          </div>
          <Drawer anchor="right" open={this.state.open} onClose={this.handleToggleDrawer(false)}>
            <div
              className={this.props.classes.list}
              role="presentation"
              onClick={this.handleToggleDrawer(false)}
              onKeyDown={this.handleToggleDrawer(false)}
            >
              <List>
                {[...commonLinks, ...extraLinks].map(link =>
                  <RouterLink key={link.to} className={this.props.classes.menuLink} to={link.to}>
                    <ListItem button>
                      <ListItemIcon>{link.icon}</ListItemIcon>
                      <ListItemText primary={link.name}/>
                    </ListItem>
                  </RouterLink>
                )}
              </List>
              <Divider/>
              <List>
                <Link className={this.props.classes.menuLink} href={config.PANEL_URL}>
                  <ListItem button>
                    <ListItemIcon><Person/></ListItemIcon>
                    <ListItemText primary="Log in"/>
                  </ListItem>
                </Link>
              </List>
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Menu);
