import { Container, Grid, Typography, withStyles } from '@material-ui/core';
import { Component } from 'react';

const styles = {
  text: {
    marginTop: 48
  }
};

class NotFound extends Component {
  render() {
    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" align="center" className={this.props.classes.text}>404 Not found</Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(NotFound);
