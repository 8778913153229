import { CssBaseline, withStyles } from '@material-ui/core';
import { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PageFooter from './components/common/PageFooter';
import ScrollToTop from './components/common/ScrollToTop';
import Contact from './components/contact/Contact';
import Docs from './components/docs/Docs';
import Main from './components/main/Main';
import Menu from './components/menu/Menu';
import NotFound from './components/not-found/NotFound';
import Pricing from './components/pricing/Pricing';
import Privacy from './components/privacy/Privacy';
import Product from './components/product/Product';
import Support from './components/support/Support';
import Terms from './components/terms/Terms';

const styles = {
  wrapper: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  }
};

class App extends Component {
  render() {
    return (
      <div className={this.props.classes.wrapper}>
        <CssBaseline/>
        <BrowserRouter>
          <ScrollToTop/>
          <Menu/>
          <Switch>
            <Route path="/product" component={Product} exact/>
            <Route path="/pricing" component={Pricing} exact/>
            <Route path="/support" component={Support} exact/>
            <Route path="/docs" component={Docs} exact/>
            <Route path="/privacy" component={Privacy} exact/>
            <Route path="/terms" component={Terms} exact/>
            <Route path="/contact" component={Contact} exact/>
            <Route path="/" component={Main} exact/>
            <Route path="/*" component={NotFound}/>
          </Switch>
          <PageFooter/>
        </BrowserRouter>
      </div>
    );
  }
}

export default withStyles(styles)(App);
