const config = {
  prod: {
    PANEL_URL: 'https://panel.conventionsbot.io',
  },
  stage: {
    PANEL_URL: 'https://stage.panel.conventionsbot.io',
  },
  dev: {
    PANEL_URL: 'http://localhost:3000',
    // PANEL_URL: 'https://dev.panel.conventionsbot.io',
  },
};

export default config[process.env.REACT_APP_TARGET || 'dev'];
