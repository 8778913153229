import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Link,
  Typography,
  withStyles
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ExpandMore, Launch } from '@material-ui/icons';
import { Component } from 'react';
import PageHeader from '../common/PageHeader';
import enable from './img/enable.png';

const externalIcon = <Launch style={{ fontSize: '1rem', position: 'relative', top: 2 }}/>;

const accordion = [
  {
    question: 'Does Conventionsbot have an access to my code?',
    answer: <span>
      <p>No. Conventionsbot only receives pull requests metadata (pull request name, author, repository name, commit messages etc.). Conventionsbot doesn't have an access to your codebase.</p>
    </span>
  },
  {
    question: 'How am I going to be charged for the Conventionsbot service?',
    answer: <span>
      <p>Github will automatically add a fee and charge credit card attached to your Github account. You can find billing details (including current plan) in Settings -> Billing & plans section.</p>
      <p>For more information please read this <Link href="https://docs.github.com/en/billing/managing-billing-for-github-marketplace-apps/about-billing-for-github-marketplace" target="_blank">Github article {externalIcon}</Link>.</p>
    </span>
  },
  {
    question: 'How can I cancel the service?',
    answer: <span>
      <p>In order to cancel the service you need to follow 2 steps. Go to your organization settings -> Billing & plans. Find Naming Conventions Bot app on the list and and click Edit -> Cancel plan.</p>
      <p>Cancelling plan doesn't automatically remove installed Github app. In order to remove Github app go to your organization settings -> Installed GitHub Apps. Find Naming Conventions Bot on the list and click Configure. Scroll to the bottom and click Uninstall.</p>
      <p>For more information please read this <Link href="https://docs.github.com/en/billing/managing-billing-for-github-marketplace-apps/canceling-a-github-marketplace-app" target="_blank">Github article {externalIcon}</Link>.</p>
    </span>
  },
  {
    question: 'Can I upgrade or downgrade plan in the future?',
    answer: <span>
      <p>Yes, you can upgrade/downgrade plans at any time. Upgrades are effective immediately. Downgrades are effective at the end of billing period.</p>
      <p>For more information please read those Github articles: <Link href="https://docs.github.com/en/billing/managing-billing-for-github-marketplace-apps/upgrading-the-billing-plan-for-a-github-marketplace-app" target="_blank">upgrading {externalIcon}</Link>
        , <Link href="https://docs.github.com/en/billing/managing-billing-for-github-marketplace-apps/downgrading-the-billing-plan-for-a-github-marketplace-app" target="_blank">downgrading {externalIcon}</Link>.
      </p>
    </span>
  },
  {
    question: 'Who can manage naming conventions via Conventionsbot admin panel?',
    answer: <span>
      <p>Currently naming conventions can be managed only by Github organization owners. You can also manage naming conventions if you installed Naming Conventions Bot in your own Github account.</p>
    </span>
  },
  {
    question: 'Why "Enable For All" toggle in "Global Settings" section is disabled?',
    answer: <span>
      <p><img src={enable} alt="Enable for all" style={{ width: '100%' }}/></p>
      <p>Enable for all button can be toggled only if your billing plan can handle more repositories than your organization owns.</p>
      <p>E.g.1 If you purchased plan that handles up to 50 repositories, your organisation owns 75 repositories and during Naming Conventions Bot Github app installation you selected "All repositories" the Enable For All toggle is disabled.</p>
      <p>E.g.2 If you purchased plan that handles up to 50 repositories, your organisation owns 75 repositories and during Naming Conventions Bot Github app installation you selected only 20 repositories that you want to monitor the Enable For All toggle is enabled</p>
      <p>E.g.3 If you purchased plan that handles up to 100 repositories, your organisation owns 80 repositories and during Naming Conventions Bot Github app installation you selected "All repositories" the Enable For All toggle is enabled.</p>
    </span>
  },
  {
    question: 'Which regular expressions flavour Conventionsbot uses?',
    answer: <span>
      <p>Conventionsbot uses PCRE syntax (using PCRE2 library). It's mostly compatible with other regular expression engines and provides some additional features like look-behind and recursion. For more information please read <Link href="https://en.wikipedia.org/wiki/Perl_Compatible_Regular_Expressions" target="_blank">PCRE Wikipedia article {externalIcon}</Link>
        , and <Link href="https://www.regular-expressions.info/quickstart.html" target="_blank">Regular-Expressions.info quickstart article {externalIcon}</Link>.
      </p>
    </span>
  },
  {
    question: 'How can I test and debug my regular expressions?',
    answer: <span>
      <p>There are many online services that allows to build test and debug regular expressions. We recommend using <Link href="https://regex101.com/" target="_blank">regex101.com {externalIcon}</Link>, but there are other alternatives.</p>
    </span>
  }
];

const styles = {
  heading: {
    color: grey[800],
    fontWeight: 700,
  },
};

class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
    };
  }

  handleChange = (index) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? index : null });
  };

  render() {
    return (
      <Container>
        <PageHeader
          title="Support"
          subtitle="Please find frequently asked questions and answers. In case you need extra support please do not hesitate to contact us."
        />
        <Grid container>
          <Grid item xs={12}>
            {accordion.map((item, index) =>
              <Accordion key={index} expanded={this.state.expanded === index} onChange={this.handleChange(index)}>
                <AccordionSummary
                  expandIcon={<ExpandMore/>}
                  aria-controls={'panel' + index + '-content'}
                  id={'panel' + index + '-header'}
                >
                  <Typography className={this.props.classes.heading}>{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {item.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(Support);
