import { withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './img/logo.png';

const styles = theme => ({
  logo: {
    width: 40,
    marginRight: 10,
  },
  brand: {
    color: grey[100],
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: 'auto',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
});

class Brand extends Component {
  render() {
    return (
      <Link to="/" className={this.props.classes.brand}>
        <img src={logo} alt="Logo" className={this.props.classes.logo}/>
        Conventionsbot
      </Link>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Brand);
