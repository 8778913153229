import { Button, Container, Grid, Link, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { GitHub } from '@material-ui/icons';
import { jarallax } from 'jarallax';
import { Component } from 'react';
import config from '../../config';
import background from './img/2.jpg';
import img1 from './img/img1.png';
import img2 from './img/img2.png';
import img3 from './img/img3.png';

const styles = {
  button: {
    marginTop: 40,
  },
  image: {
    width: '90%',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;'
  },
  jumboPane: {
    alignItems: 'center',
    height: 700,
  },
  jumboSubtitle: {
    color: grey[700],
  },
  login: {
    color: grey[100],
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'none',
    }
  },
  pane: {
    display: 'flex',
    height: 440,
  },
  paneSide: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
};

class Main extends Component {
  componentDidMount() {
    jarallax(document.querySelectorAll('.jarallax'), {
      speed: 0.5,
    });
  }

  render() {
    return (
      <div>
        <div className="jarallax">
          <img className="jarallax-img" src={background} alt="Background"/>
          <Container>
            <Grid container className={this.props.classes.jumboPane}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h3" color="primary" align="center">Automatically validate naming conventions</Typography>
                    <Typography variant="subtitle1" align="center" className={this.props.classes.jumboSubtitle}>
                      Conventionsbot automatically validates naming conventions of pull requests, branches and commit messages in your Github repositories.
                    </Typography>
                    <Grid container direction="column" alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        <Link href={config.PANEL_URL} className={this.props.classes.login}>
                          <Button
                            className={this.props.classes.button}
                            color="primary"
                            variant="outlined"
                            startIcon={<GitHub/>}
                            size="large"
                          >
                            Log in with Github
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>

        <Container>
          <Grid container>
            <Grid item xs={12} className={this.props.classes.pane}>
              <Grid container>
                <Grid item xs={12} sm={6} className={this.props.classes.paneSide}>
                  <Typography variant="h4" color="primary" align="center">Validate pull requests</Typography>
                  <Typography variant="subtitle1" align="center">
                    Automatically validate naming conventions of pull requests, branches and commit messages.
                    Receive validation statuses in your pull request summary.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={this.props.classes.paneSide}>
                  <img className={this.props.classes.image} src={img1} alt="Validate pull requests"/>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={this.props.classes.pane}>
              <Grid container>
                <Grid item xs={12} sm={6} className={this.props.classes.paneSide}>
                  <img className={this.props.classes.image} src={img2} alt="Ensure consistency"/>
                </Grid>
                <Grid item xs={12} sm={6} className={this.props.classes.paneSide}>
                  <Typography variant="h4" color="primary" align="center">Ensure consistency</Typography>
                  <Typography variant="subtitle1" align="center">
                    Define global naming conventions for your organization or individual repositories. Enable
                    automatic validation and let Conventionsbot to take care of consistency.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={this.props.classes.pane}>
              <Grid container>
                <Grid item xs={12} sm={6} className={this.props.classes.paneSide}>
                  <Typography variant="h4" color="primary" align="center">Set up in less than 5 minutes</Typography>
                  <Typography variant="subtitle1" align="center">
                    Use available convention templates or create your own custom convention using regular expressions.
                    Conventionsbot gives you full flexibility.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={this.props.classes.paneSide}>
                  <img className={this.props.classes.image} src={img3} alt="Set up in less than 5 minutes"/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(Main);
