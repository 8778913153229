import { Container, Grid, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Component } from 'react';
import PageHeader from '../common/PageHeader';

const styles = theme => ({
  header: {
    color: grey[800],
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  list: {
    color: grey[700],
    fontSize: '1rem',
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    color: grey[700],
    marginBottom: theme.spacing(2),
  },
  subheader: {
    color: grey[600],
    marginBottom: theme.spacing(1),
  },
});

class Privacy extends Component {
  render() {
    return (
      <Container>
        <PageHeader
          title="Privacy policy"
          subtitle="Last updated: 14 September 2021"
        />
        <Grid container>
          <Grid item>
            <Typography variant="body1" className={this.props.classes.paragraph}>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</Typography>

            <Typography variant="h5" className={this.props.classes.header}>Interpretation and Definitions</Typography>
            <Typography variant="h6" className={this.props.classes.subheader}>Interpretation</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</Typography>
            <Typography variant="h6" className={this.props.classes.subheader}>Definitions</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>For the purposes of this Privacy Policy:</Typography>
            <ul className={this.props.classes.list}>
              <li className={this.props.classes.listItem}>
                <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Conventionsbot.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>Country</strong> refers to: Poland
              </li>
              <li className={this.props.classes.listItem}>
                <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>Service</strong> refers to the Website.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
              </li>
              <li className={this.props.classes.listItem}>
                <strong>Website</strong> refers to Conventionsbot, accessible from <a className={this.props.classes.link} href="https://conventionsbot.io" rel="external nofollow noopener noreferrer" target="_blank">https://conventionsbot.io</a>
              </li>
              <li className={this.props.classes.listItem}>
                <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
              </li>
            </ul>

            <Typography variant="h5" className={this.props.classes.header}>Collecting and Using Your Personal Data</Typography>
            <Typography variant="h6" className={this.props.classes.subheader}>Types of Data Collected</Typography>

            <Typography variant="subtitle2" className={this.props.classes.subheader}>Personal Data</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</Typography>

            <Typography variant="subtitle2" className={this.props.classes.subheader}>Usage Data</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>Usage Data is collected automatically when using the Service.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</Typography>

            <Typography variant="subtitle2" className={this.props.classes.subheader}>Tracking Technologies and Cookies</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>We use Google Analytics to track the activity on Our Service.</Typography>

            <Typography variant="subtitle2" className={this.props.classes.subheader}>Github data</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>
              Installing Conventionsbot Github app grants Us an access to some data that You have shared with Github. This includes Your personal data, Your organizations and repositories metadata.
            </Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>
              In order to provide the Service, we need to store the following information:
            </Typography>

            <ul className={this.props.classes.list}>
              <li className={this.props.classes.listItem}>Organization names and ids</li>
              <li className={this.props.classes.listItem}>Repository names, visibilities (public/private) and ids</li>
              <li className={this.props.classes.listItem}>Pull Request ids</li>
              <li className={this.props.classes.listItem}>Github access tokens</li>
            </ul>
            <Typography variant="body1" className={this.props.classes.paragraph}>
              We do not store Your email address. We do not have an access to Your repositories codebase.
            </Typography>


            <Typography variant="h6" className={this.props.classes.subheader}>Use of Your Personal Data</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>The Company may use Personal Data for the following purposes:</Typography>
            <ul className={this.props.classes.list}>
              <li className={this.props.classes.listItem}>
                <strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends and to evaluate and improve our Service, products, services, and your experience.
              </li>
            </ul>
            <Typography variant="body1" className={this.props.classes.paragraph}>We may share Your personal information in the following situations:</Typography>
            <ul className={this.props.classes.list}>
              <li className={this.props.classes.listItem}>
                <strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
              </li>
              <li className={this.props.classes.listItem}>
                <strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.
              </li>
            </ul>
            <Typography variant="h6" className={this.props.classes.subheader}>Retention of Your Personal Data</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</Typography>
            <Typography variant="h6" className={this.props.classes.subheader}>Transfer of Your Personal Data</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</Typography>
            <Typography variant="h6" className={this.props.classes.subheader}>Disclosure of Your Personal Data</Typography>
            <Typography variant="subtitle2" className={this.props.classes.subheader}>Business Transactions</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</Typography>
            <Typography variant="subtitle2" className={this.props.classes.subheader}>Law enforcement</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</Typography>
            <Typography variant="subtitle2" className={this.props.classes.subheader}>Other legal requirements</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</Typography>
            <ul className={this.props.classes.list}>
              <li className={this.props.classes.listItem}>Comply with a legal obligation</li>
              <li className={this.props.classes.listItem}>Protect and defend the rights or property of the Company</li>
              <li className={this.props.classes.listItem}>Prevent or investigate possible wrongdoing in connection with the Service</li>
              <li className={this.props.classes.listItem}>Protect the personal safety of Users of the Service or the public</li>
              <li className={this.props.classes.listItem}>Protect against legal liability</li>
            </ul>
            <Typography variant="h6" className={this.props.classes.subheader}>Security of Your Personal Data</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</Typography>

            <Typography variant="h5" className={this.props.classes.header}>Links to Other Websites</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</Typography>

            <Typography variant="h5" className={this.props.classes.header}>Changes to this Privacy Policy</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>We will let You know via a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</Typography>

            <Typography variant="h5" className={this.props.classes.header}>Contact Us</Typography>
            <Typography variant="body1" className={this.props.classes.paragraph}>If you have any questions about this Privacy Policy, You can contact us by email: <a className={this.props.classes.link} href="mailto:&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#099;&#111;&#110;&#118;&#101;&#110;&#116;&#105;&#111;&#110;&#115;&#098;&#111;&#116;&#046;&#105;&#111;">&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#099;&#111;&#110;&#118;&#101;&#110;&#116;&#105;&#111;&#110;&#115;&#098;&#111;&#116;&#046;&#105;&#111;</a></Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(Privacy);
