import { Container, Grid, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Component } from 'react';
import { Link } from 'react-router-dom';

const links = [
  {
    to: '/product',
    desc: 'Product',
  },
  {
    to: '/pricing',
    desc: 'Pricing',
  },
  {
    to: '/support',
    desc: 'Support',
  },
  {
    to: '/docs',
    desc: 'Docs',
  },
  {
    to: '/terms',
    desc: 'Terms of service',
  },
  {
    to: '/privacy',
    desc: 'Privacy',
  },
  {
    to: '/contact',
    desc: 'Contact',
  },
];

const styles = theme => ({
  copyright: {
    color: grey[600],
    marginTop: theme.spacing(3),
  },
  dot: {
    fontSize: '1.25rem',
    fontWeight: 700,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  footer: {
    color: grey[100],
    backgroundColor: '#2B2D31',
    marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  footerWrapper: {
    marginTop: 'auto',
  },
  link: {
    color: grey[100],
    textDecoration: 'none',
  },
  linkWrapper: {
    marginBottom: theme.spacing(1),
  },
  linksWrapper: {
    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
});

class PageFooter extends Component {
  render() {
    return (
      <div className={this.props.classes.footerWrapper}>
        <div className={this.props.classes.footer}>
          <Container>
            <Grid container direction="column" alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <div className={this.props.classes.linksWrapper}>
                  {links.map((link, index) =>
                    <div key={link.to} className={this.props.classes.linkWrapper}>
                      <span className={this.props.classes.dot}>{index !== 0 && '⋅'}</span>
                      <Link to={link.to} key={link.to} className={this.props.classes.link}>
                        {link.desc}
                      </Link>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={this.props.classes.copyright}>
                  © {new Date().getFullYear()} Conventionsbot
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PageFooter);
