import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Check } from '@material-ui/icons';
import { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import config from '../../config';
import PageHeader from '../common/PageHeader';

const plans = [
  {
    name: 'Startup',
    price: 'Free',
    period: null,
    features: ['Up to 10 repositories', 'Unlimited users', 'Private/public repos'],
    button: {
      disabled: false,
      text: 'Get started',
      variant: 'contained',
    }
  },
  {
    name: 'MiniBot',
    price: '$10',
    period: 'month',
    features: ['Up to 50 repositories', 'Unlimited users', 'Private/public repos', '14-day free trial'],
    button: {
      disabled: false,
      text: 'Get started',
      variant: 'contained',
    }
  },
  {
    name: 'StandardBot',
    price: '$50',
    period: 'month',
    features: ['Up to 500 repositories', 'Unlimited users', 'Private/public repos', '14-day free trial'],
    button: {
      disabled: false,
      text: 'Get started',
      variant: 'contained',
    }
  },
  {
    name: 'JumboBot',
    price: '$150',
    period: 'month',
    features: ['Up to 5000 repositories', 'Unlimited users', 'Private/public repos', '14-day free trial'],
    button: {
      disabled: false,
      text: 'Get started',
      variant: 'contained',
    }
  },
];

const styles = theme => ({
  bottomText: {
    color: grey[500],
    marginTop: theme.spacing(1),
  },
  bottomLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    marginBottom: 'auto',
  },
  cardHeader: {
    backgroundColor: grey[200],
  },
  cardPricing: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  login: {
    color: grey[100],
    textDecoration: 'none',
    width: '100%',

    '&:hover': {
      textDecoration: 'none',
    }
  },
});

class Pricing extends Component {
  render() {
    return (
      <Container>
        <PageHeader
          title="Pricing"
          subtitle="Conventionsbot provides flexible plans that scale with your business. All plans have unlimited number of users. Select one of the available plans to continue."
        />
        <Grid container spacing={3} className={this.props.classes.container}>
          {plans.map((plan, index) => <Grid item xs={12} sm={6} md={3} key={index}>
            <Card elevation={2} className={this.props.classes.card}>
              <CardHeader
                title={plan.name}
                titleTypographyProps={{ align: 'center' }}
                className={this.props.classes.cardHeader}
              />
              <CardContent className={this.props.classes.cardContent}>
                <div className={this.props.classes.cardPricing}>
                  <Typography variant="h4">{plan.price}</Typography>
                  {plan.period && <Typography variant="subtitle1" color="textSecondary"> / {plan.period}</Typography>}
                </div>
                <List>
                  {plan.features.map((feature, index) => <ListItem key={index}>
                      <ListItemIcon><Check color="primary"/></ListItemIcon>
                      <ListItemText>{feature}</ListItemText>
                    </ListItem>
                  )}
                </List>
              </CardContent>
              <CardActions>
                <Link href={config.PANEL_URL} className={this.props.classes.login}>
                  <Button fullWidth variant={plan.button.variant} color="primary" disabled={plan.button.disabled}>
                    {plan.button.text}
                  </Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>)}
          <Grid item xs={12}>
            <Typography align="center" variant="h6" className={this.props.classes.bottomText}>
              Need to handle more repositories? Please <RouterLink to="/contact" className={this.props.classes.bottomLink}>contact us</RouterLink>.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Pricing);
