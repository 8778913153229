import { Button, Container, Grid, Link } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { Component } from 'react';
import PageHeader from '../common/PageHeader';

class Contact extends Component {
  render() {
    return (
      <Container>
        <PageHeader
          title="Contact"
          subtitle="If you had any questions or would like to provide a feedback please contact us using below link."
        />
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Link href="mailto:&#099;&#111;&#110;&#116;&#097;&#099;&#116;&#064;&#099;&#111;&#110;&#118;&#101;&#110;&#116;&#105;&#111;&#110;&#115;&#098;&#111;&#116;&#046;&#105;&#111;">
              <Button startIcon={<Email/>} variant="contained" color="primary">Send us a message</Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Contact;
